import { useState, useRef, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { Button, Flex } from '..';
import styles from './FileInputWithPreview.module.scss';
import { useAdaptiveWidth } from '@/hooks/useAdaptiveWidth';

export const FileInputWithPreview = ({
  name,
  required,
  maxLength,
  accept = '.jpg,.jpeg,.webp,.gif,.webp,.avi,.mp4,.mov,.mp3,.wav,.png',
  label,
}) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    rules: {
      required: { value: required, message: 'This field is required' },
      maxLength: {
        value: maxLength,
        message: 'File size exceeds maximum limit',
      },
    },
    defaultValue: null,
    control,
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [inputKey, setInputKey] = useState(Date.now());
  const isMuted = useRef(true);
  const { isTablet } = useAdaptiveWidth();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileURL = URL.createObjectURL(file);
    const fileSize = (file.size / (1024 * 1024)).toFixed(2);

    setSelectedFile(fileURL);
    setFileType(file.type);
    setFileName(file.name);
    setFileSize(fileSize);

    field.onChange(file);
  };

  const handleDelete = () => {
    setSelectedFile(null);
    setFileType(null);
    setFileName('');
    setFileSize('');
    field.onChange(null);
    setInputKey(Date.now());
  };

  useEffect(() => {
    if (!field.value) {
      handleDelete();
    }
  }, [field.value]);

  return (
    <Flex vertical gap={10} className={styles.uploadSection}>
      {label && (
        <Flex gap={4}>
          <p>{label}</p>
          {required && <span className={styles.required}>*</span>}
        </Flex>
      )}

      <Flex vertical gap={10}>
        <div
          className={cn(styles.uploadBox, {
            [styles.fullWidth]: selectedFile,
          })}
        >
          <label htmlFor={name} className={styles.uploadLabel}>
            {!selectedFile ? (
              <Flex vertical gap={10} justify="center" align="center">
                <span className={styles.title}>Click to upload</span>
                <ul className={styles.fileFormats}>
                  <li>Supported file types and size limit</li>
                  <li>Image: JPG, JPEG, PNG, GIF, WEBP (100MB)</li>
                  <li>Video: AVI, MP4, MOV (100MB)</li>
                  <li>Audio: MP3, WAV (100MB)</li>
                </ul>
              </Flex>
            ) : fileType.startsWith('video') ? (
              <video
                className={styles.mediaPreview}
                autoPlay
                controls
                muted={isMuted.current}
              >
                <source src={selectedFile} type={fileType} />
              </video>
            ) : (
              <img
                src={selectedFile}
                className={styles.mediaPreview}
                alt="Preview"
              />
            )}
          </label>

          <input
            id={name}
            type="file"
            accept={accept}
            className={styles.hiddenInput}
            onChange={handleFileChange}
            key={inputKey}
          />
        </div>

        {selectedFile && (
          <div className={styles.fileDetails}>
            <Flex
              justify="space-between"
              align={isTablet ? undefined : 'center'}
              className={styles.fileInfo}
              vertical={isTablet}
              gap={10}
            >
              <div>
                <div className={styles.fileName}>{fileName || 'Name Item'}</div>
                <div className={styles.fileSize}>{fileSize} MB</div>
              </div>
              <Button onClick={handleDelete} color="blue">
                Delete
              </Button>
            </Flex>
          </div>
        )}
      </Flex>

      {fieldState.error && (
        <p className={styles.errorText}>{fieldState.error.message}</p>
      )}
    </Flex>
  );
};
