export const SearchIcon = ({ color = 'white', className, onClick, size }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <path
        d="M10.737 4.00005C7.01622 4.00005 4 7.01653 4 10.737C4 14.4575 7.01622 17.474 10.737 17.474C12.2837 17.474 13.7497 16.9426 14.8868 16.0668L18.5528 19.7637C18.8818 20.0922 19.4345 20.0922 19.7635 19.7637C20.0923 19.4345 20.0923 18.882 19.7635 18.5528L16.072 14.8811C16.9485 13.7442 17.4739 12.284 17.4739 10.737C17.4739 7.01653 14.4577 4.00005 10.737 4.00005ZM10.737 5.68429C13.5275 5.68429 15.7897 7.94623 15.7897 10.737C15.7897 13.5278 13.5275 15.7898 10.737 15.7898C7.94643 15.7898 5.68424 13.5278 5.68424 10.737C5.68424 7.94623 7.94643 5.68429 10.737 5.68429Z"
        fill={color}
        style={{ transition: 'all 0.3s' }}
      />
    </svg>
  );
};
