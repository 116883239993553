export const APP_REDUCER_NAME = 'appContainer';

export const ACTIONS = {
  SET_MENU_ACTIVE: 'SET_MENU_ACTIVE',
  SET_OWNED_NFTS: 'SET_OWNED_NFTS',
  SET_ON_SALE_NFTS: 'SET_ON_SALE_NFTS',
  SET_CREATED_NFTS: 'SET_CREATED_NFTS',
  SET_IS_TRANSFER_MODE: 'SET_IS_TRANSFER_MODE',
  SET_TRANSFER_LIST: 'SET_TRANSFER_LIST',
  SET_TRANSFER_APPROVAL: 'SET_TRANSFER_APPROVAL',
};
