export const ProfileIcon = ({ color = 'white', className, onClick, size }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <g>
        <circle cx="12" cy="7" r="5" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.31095 21.6334C3.62659 20.9118 2.94313 18.8776 4.05769 17.4231C5.88534 15.038 8.7631 13.5 12.0001 13.5C15.2371 13.5 18.1148 15.038 19.9425 17.4231C21.057 18.8776 20.3736 20.9118 18.6892 21.6334C16.6362 22.513 14.375 23 12.0001 23C9.62516 23 7.36398 22.513 5.31095 21.6334Z"
          fill={color}
          style={{ transition: 'all 0.3s' }}
        />
      </g>
    </svg>
  );
};
