import { FormProvider, useForm } from 'react-hook-form';
import { BaseModal, Select, Input, TextArea, Flex } from '@/lib';

import { useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from '@/lib/Button';
import Link from 'next/link';
import { BACKEND_ADDRESS } from '@/constants';

import { fetchData } from '@/services/fetchData';

const FEEDBACK_OPTIONS = [
  { label: 'Feedback', value: 'feedback' },
  { label: 'Complain', value: 'complain' },
  { label: 'Bug report', value: 'bug_report' },
  { label: 'Other', value: 'other' },
];

export const ContactUsModal = ({ onCloseModal }) => {
  const [feedbackType, setFeedbackType] = useState(FEEDBACK_OPTIONS[0]);

  const methods = useForm({
    defaultValues: {
      email: '',
      message: '',
      type: feedbackType.value,
    },
    mode: 'onSubmit',
  });

  const handleFeedbackTypeChange = (value) => {
    setFeedbackType(value);
  };

  const onSubmit = async (data) => {
    await toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const response = await fetchData({
            url: `${BACKEND_ADDRESS}/feedback`,
            method: 'POST',
            body: data,
          });

          if (response) {
            resolve();
            onCloseModal();
          } else {
            throw new Error();
          }
        } catch (error) {
          reject('Encountered error 🤯');
        }
      }),
      {
        pending: 'Sending your feedback...',
        success: 'Feedback submitted 👌',
        error: (err) => err,
      },
    );
  };

  return (
    <BaseModal title="Request help / Send feedback" onClose={onCloseModal}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Flex vertical gap={32}>
            <div>
              Please contact us via email{' '}
              <Link href="mailto:help@8legends.ai">
                <span>help@8legends.ai</span>
              </Link>
              {'\n'}
              or join our{' '}
              <Link href="https://t.me/ArtLegends8" target="_blank">
                <span>Telegram group</span>
              </Link>{' '}
              and ask directly to a team member
            </div>
            <Flex vertical gap={12}>
              <Select
                title="Feedback"
                name="type"
                selected={feedbackType}
                options={FEEDBACK_OPTIONS}
                onChange={handleFeedbackTypeChange}
              />
              <Input
                placeholder="Email"
                required
                name="email"
                pattern={/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/}
              />
              <TextArea
                name="message"
                placeholder="How can we help you?"
                required
                minLength={10}
              />
            </Flex>
            <Flex gap={12} justify="space-between">
              <Button
                variant="filled"
                color="white"
                fullWidth
                onClick={onCloseModal}
              >
                Join TG
              </Button>
              <Button
                noShadow
                variant="filled"
                color="orange"
                type="submit"
                fullWidth
              >
                Send
              </Button>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </BaseModal>
  );
};
