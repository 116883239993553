import styles from './StatusTag.module.scss';

export const StatusTag = ({ className, text, noIcon }) => {
  return (
    <div className={`${styles.statusTag} ${className}`}>
      {!noIcon && (
        <img
          loading="lazy"
          src="/svg/live.svg"
          alt="live"
          className={styles.icon}
        />
      )}
      <span className={styles.text}>{text}</span>
    </div>
  );
};
