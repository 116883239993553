import { createReducer } from '@reduxjs/toolkit';
import {
  setCreatedNFTs,
  setIsTransferMode,
  setMenuActive,
  setOnSaleNFTs,
  setOwnedNFTs,
  setTranferList,
  setTransferApproval,
} from './actions';

const initialState = {
  menuActive: false,
  ownedNFTs: [],
  createdNFTs: [],
  onSaleNFTs: [],
  isTransferMode: false,
  transferList: [],
  transferApproval: false,
};

export const appReducer = createReducer(initialState, (builder) => {
  builder.addCase(setMenuActive, (state, action) => {
    state.menuActive = action.payload;
  });
  builder.addCase(setOwnedNFTs, (state, action) => {
    state.ownedNFTs = action.payload;
  });
  builder.addCase(setCreatedNFTs, (state, action) => {
    state.createdNFTs = action.payload;
  });
  builder.addCase(setOnSaleNFTs, (state, action) => {
    state.onSaleNFTs = action.payload;
  });
  builder.addCase(setIsTransferMode, (state, action) => {
    state.isTransferMode = action.payload;
  });
  builder.addCase(setTranferList, (state, action) => {
    state.transferList = action.payload;
  });
  builder.addCase(setTransferApproval, (state, action) => {
    state.transferApproval = action.payload;
  });
});
