import cn from 'classnames';
import styles from './TableRow.module.scss';

export function TableRow({ grid, className, children, onClick }) {
  const wrapperClassName = cn(styles.wrapper, className);

  return (
    <div
      className={wrapperClassName}
      style={{ gridTemplateColumns: grid }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
