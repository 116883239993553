import { fromWei } from '@/services/blockchain';

export const formatFtnValues = (value) => {
  const valueToNumber = Number(fromWei(value)).toFixed(2);
  const number = parseFloat(valueToNumber);

  if (number % 1 === 0) {
    return number;
  }

  return valueToNumber;
};
