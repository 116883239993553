import styles from './BlogBlock.module.scss';
import { BlogCard } from '../BlogCard';
import { useRouter } from 'next/router';
import { ArrowIcon, Button, Flex, Title } from '@/lib';
import { BLACK_COLOR, WHITE_COLOR } from '@/constants';
import { useState } from 'react';
import { useAdaptiveWidth } from '@/hooks/useAdaptiveWidth';

export function BlogBlock({ items, className }) {
  const { push } = useRouter();
  const firstItem = items[0];
  const { isTablet } = useAdaptiveWidth();
  const [hovered, setHovered] = useState(false);

  return (
    <Flex vertical gap={40} className={className}>
      <Title link={{ text: 'View all posts', url: '/blog' }}>Blog</Title>
      <Flex
        justify="space-between"
        vertical={isTablet}
        className={styles.content}
        gap={isTablet ? 24 : 0}
      >
        <BlogCard
          image={firstItem.image ?? '/webp/empty.webp'}
          tag={firstItem.tag ?? 'INSIGHTS'}
          title={firstItem.title}
          desc={firstItem.subtitle}
          className={styles.card}
          mediaClass={styles.cardImg}
          handleClick={() => push(`/blog/${firstItem.slug}`)}
        />
        <Flex vertical align="flex-end" gap={20} className={styles.blogColumn}>
          {items.slice(1, 4).map((item) => (
            <div
              className={styles.cardMini}
              key={item.id}
              onClick={() => push(`/blog/${item.slug}`)}
            >
              <div className={styles.cardMiniInfo}>
                <span className={styles.cardMiniTag}>
                  {item.tag ?? 'INSIGHTS'}
                </span>
                <p className={styles.cardMiniTitle}>{item.title}</p>
              </div>
              <div className={styles.cardMiniImgWrapper}>
                <img
                  src={item.image ?? '/webp/empty.webp'}
                  alt="media"
                  className={styles.cardMiniImg}
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          ))}
        </Flex>
        {isTablet && (
          <Button
            onClick={() => push('/blog')}
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <span>View all posts</span>
            <ArrowIcon
              color={hovered ? WHITE_COLOR : BLACK_COLOR}
              className={styles.icon}
            />
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
