import styles from './Divider.module.scss';
import cn from 'classnames';

export const Divider = ({ className, noMargin }) => {
  const dividerClassName = cn(
    styles.divider,
    {
      [styles.noMargin]: noMargin,
    },
    className,
  );

  return <hr className={dividerClassName} />;
};
