import styles from './Option.module.scss';
import cn from 'classnames';

export function Option({ className, onClick, option }) {
  const handleClick = (clickedOption) => () => {
    onClick(clickedOption);
  };

  const optionClassName = cn(styles.option, className);

  return (
    <li
      onClick={handleClick(option)}
      className={optionClassName}
      value={option.value}
      tabIndex={0}
    >
      {option.icon && (
        <img
          loading="lazy"
          src={option.icon}
          className={styles.icon}
          alt="currency"
        />
      )}
      {option.label}
    </li>
  );
}
