import cn from 'classnames';
import styles from './WaveVector.module.scss';

export function WaveVector({ className }) {
  const wrapperClassName = cn(styles.wrapper, className);

  return (
    <div className={wrapperClassName}>
      <img
        loading="lazy"
        src="/webp/wave.webp"
        alt="vector"
        className={styles.wave}
      />
    </div>
  );
}
