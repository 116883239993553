export const ArrowIcon = ({ color = 'black', className, onClick, size }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <path
        d="M20 12C20 12.7154 19.404 13.2948 18.668 13.2948C18.0307 13.2948 10.5813 13.2948 8.59467 13.2948L12.5493 17.179L10.676 19L4.39067 12.9306C4.12933 12.6778 4 12.3383 4 11.9987C4 11.6604 4.13067 11.3221 4.39067 11.0694L10.676 5L12.5493 6.82096L8.59467 10.7052C10.5813 10.7052 18.0307 10.7052 18.668 10.7052C19.404 10.7052 20 11.2846 20 12Z"
        fill={color}
        style={{ transition: 'all 0.3s' }}
      />
    </svg>
  );
};
