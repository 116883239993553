export const ThreeDotsIcon = ({
  color = 'white',
  className,
  onClick,
  size,
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <path
        d="M3.01074 7.50732C2.18199 7.50732 1.51074 8.17857 1.51074 9.00732C1.51074 9.83607 2.18199 10.5073 3.01074 10.5073C3.83949 10.5073 4.51074 9.83607 4.51074 9.00732C4.51074 8.17857 3.83949 7.50732 3.01074 7.50732ZM9.01074 7.50732C8.18199 7.50732 7.51074 8.17857 7.51074 9.00732C7.51074 9.83607 8.18199 10.5073 9.01074 10.5073C9.83949 10.5073 10.5107 9.83607 10.5107 9.00732C10.5107 8.17857 9.83949 7.50732 9.01074 7.50732ZM15.0107 7.50732C14.182 7.50732 13.5107 8.17857 13.5107 9.00732C13.5107 9.83607 14.182 10.5073 15.0107 10.5073C15.8395 10.5073 16.5107 9.83607 16.5107 9.00732C16.5107 8.17857 15.8395 7.50732 15.0107 7.50732Z"
        fill={color}
        style={{ transition: 'all 0.3s' }}
      />
    </svg>
  );
};
