/* eslint-disable no-console */
import { BACKEND_ADDRESS } from '@/constants';
import axios, { AxiosError } from 'axios';

export async function fetchData({ url, customUrl, method = 'GET', body }) {
  try {
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (body) {
      options.data = body;
    }

    const response = await axios(
      customUrl ? customUrl : `${BACKEND_ADDRESS}${url}`,
      options,
    );

    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching data');

    if (error instanceof AxiosError) {
      return error.response?.data || { error: 'An error occurred' };
    }

    return { error: 'Unexpected error occurred' };
  }
}
