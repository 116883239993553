import cn from 'classnames';
import styles from './ImageCell.module.scss';

export function ImageCell({ className, img, name }) {
  const wrapperClassName = cn(styles.wrapper, className);

  return (
    <div className={wrapperClassName}>
      <img
        src={img ?? '/webp/empty.webp'}
        alt="avatar"
        className={styles.image}
        width={60}
        height={60}
      />
      <span className={styles.text}>{name}</span>
    </div>
  );
}
