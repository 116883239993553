export const InfoIcon = ({ color = 'white', className, onClick, size }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <path
        d="M10.737 4.00005C7.01622 4.00005 4 7.01653 4 10.737C4 14.4575 7.01622 17.474 10.737 17.474C12.2837 17.474 13.7497 16.9426 14.8868 16.0668L18.5528 19.7637C18.8818 20.0922 19.4345 20.0922 19.7635 19.7637C20.0923 19.4345 20.0923 18.882 19.7635 18.5528L16.072 14.8811C16.9485 13.7442 17.4739 12.284 17.4739 10.737C17.4739 7.01653 14.4577 4.00005 10.737 4.00005ZM10.737 5.68429C13.5275 5.68429 15.7897 7.94623 15.7897 10.737C15.7897 13.5278 13.5275 15.7898 10.737 15.7898C7.94643 15.7898 5.68424 13.5278 5.68424 10.737C5.68424 7.94623 7.94643 5.68429 10.737 5.68429Z"
        fill={color}
        style={{ transition: 'all 0.3s' }}
      />
      <g>
        <path
          d="M9.99984 14.1667C10.2359 14.1667 10.4339 14.0869 10.5936 13.9272C10.7533 13.7674 10.8332 13.5695 10.8332 13.3334C10.8332 13.0973 10.7533 12.8994 10.5936 12.7397C10.4339 12.5799 10.2359 12.5001 9.99984 12.5001C9.76373 12.5001 9.56581 12.5799 9.40609 12.7397C9.24636 12.8994 9.1665 13.0973 9.1665 13.3334C9.1665 13.5695 9.24636 13.7674 9.40609 13.9272C9.56581 14.0869 9.76373 14.1667 9.99984 14.1667ZM9.99984 10.8334C10.2359 10.8334 10.4339 10.7536 10.5936 10.5938C10.7533 10.4341 10.8332 10.2362 10.8332 10.0001V7.50008C10.8332 7.26397 10.7533 7.06605 10.5936 6.90633C10.4339 6.74661 10.2359 6.66675 9.99984 6.66675C9.76373 6.66675 9.56581 6.74661 9.40609 6.90633C9.24636 7.06605 9.1665 7.26397 9.1665 7.50008V10.0001C9.1665 10.2362 9.24636 10.4341 9.40609 10.5938C9.56581 10.7536 9.76373 10.8334 9.99984 10.8334Z"
          fill={color}
          style={{ transition: 'all 0.3s' }}
        />
        <circle
          cx="10"
          cy="10"
          r="6.66667"
          stroke="white"
          strokeWidth="1.66667"
          fill={color}
          style={{ transition: 'all 0.3s' }}
        />
      </g>
    </svg>
  );
};
