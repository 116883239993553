import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { Flex, Icon } from '../../lib';
import styles from './BannerGallery.module.scss';
import { Button } from '@/lib/Button';
import { truncateAddress } from '@/utils/truncateAddress';
import { useAdaptiveWidth } from '@/hooks/useAdaptiveWidth';
import { formatFtnValues } from '@/utils/formatFtnValues';
import { motion } from 'motion/react';

export const BannerGallery = ({ items }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [interactive, setInteractive] = useState(false);
  const activeCollection = items[activeItemIndex];

  const router = useRouter();
  const { isTablet } = useAdaptiveWidth();

  useEffect(() => {
    const interval = setInterval(() => {
      if (interactive) {
        return;
      }
      if (activeItemIndex === items.length - 1) {
        setActiveItemIndex(0);
      } else {
        setActiveItemIndex((prev) => prev + 1);
      }
    }, 5000);

    return () => clearTimeout(interval);
  }, [activeItemIndex, items, interactive]);

  return (
    <Flex vertical>
      <Flex vertical justify="flex-end" className={styles.banner}>
        <motion.img
          key={activeCollection.address}
          initial={{ opacity: 0 }}
          transition={{ duration: 1 }}
          animate={{ opacity: 1 }}
          src={activeCollection.banner ?? '/webp/empty.webp'}
          alt="banner"
          width="100%"
          height="100%"
          className={styles.bannerImg}
        />

        <div className={styles.bannerGradient}></div>
        <Flex justify="space-between" align="flex-end">
          <motion.div
            className={styles.bannerInfo}
            initial={{ opacity: 0 }}
            transition={{ duration: 1 }}
            animate={{ opacity: 1 }}
            key={activeItemIndex}
          >
            <h4 className={styles.bannerTitle}>{activeCollection.name}</h4>
            <div className={styles.bannerAuthor}>
              {activeCollection.avatar && (
                <img
                  src={activeCollection.avatar ?? '/webp/empty.webp'}
                  alt="creator"
                  className={styles.bannerAuthorImg}
                  width={30}
                  height={30}
                />
              )}
              <span className={styles.bannerAuthorName}>
                {truncateAddress(activeCollection.created_by_address)}
              </span>
            </div>
            <Flex gap={20} className={styles.bannerPrice}>
              <div className={styles.bannerPriceItem}>
                <span>Floor price</span>
                <div className={styles.bannerPriceItemRow}>
                  <span>
                    {activeCollection.floor_price
                      ? formatFtnValues(activeCollection.floor_price ?? 0)
                      : 0}
                  </span>
                  <img
                    loading="lazy"
                    src="/svg/ftn.svg"
                    alt="ftn"
                    width={24}
                    height={24}
                  />
                </div>
              </div>
              <div className={styles.bannerPriceItem}>
                <span>7d Volume</span>
                <div className={styles.bannerPriceItemRow}>
                  <span>
                    {activeCollection.volume_week
                      ? formatFtnValues(activeCollection.volume_week ?? 0)
                      : 0}
                  </span>
                  <img
                    loading="lazy"
                    src="/svg/ftn.svg"
                    alt="ftn"
                    width={24}
                    height={24}
                  />
                </div>
              </div>
            </Flex>
          </motion.div>
          <Button
            variant="filled"
            color="white"
            className={styles.bannerButton}
            size={isTablet ? 'small' : undefined}
            onClick={() => router.push(`/collections/${activeCollection.slug}`)}
          >
            <span>{isTablet ? 'View' : 'View collection'}</span>
            <Icon type="arrow" className={styles.arrow} />
          </Button>
        </Flex>
      </Flex>
      <Flex gap={20} className={styles.gallery}>
        {items.slice(0, 5).map((item, index) => (
          <div
            key={item.address}
            className={cn(styles.galleryItem, {
              [styles.galleryItemActive]:
                item.address === activeCollection.address,
            })}
            onClick={() => {
              setInteractive(true);
              setActiveItemIndex(index);
            }}
          >
            <img
              src={item.banner ?? '/webp/empty.webp'}
              alt="gallery"
              className={styles.galleryItemImg}
              width="100%"
              height="100%"
            />
            <div className={styles.galleryItemTitle}>{item.name}</div>
          </div>
        ))}
      </Flex>
    </Flex>
  );
};
