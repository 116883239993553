import styles from './FeedbackSlider.module.scss';
import { FeedbackCard } from '../FeedbackCard';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import { Swiper, SwiperSlide } from 'swiper/react';
import cn from 'classnames';
import { useState, useRef } from 'react';
import { Icon, Title } from '../../lib';
import { useAdaptiveWidth } from '@/hooks/useAdaptiveWidth';

function ArrowButton({ onClick, className }) {
  const wrapperClassName = cn(styles.arrowButton, className);
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={wrapperClassName}
      onClick={onClick}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Icon type="arrow" color={hovered ? '#000' : '#fff'} />
    </div>
  );
}

function SliderButton({ isNextButton, onChange }) {
  const handleClick = () => {
    onChange();
  };

  return isNextButton ? (
    <ArrowButton onClick={handleClick} className={styles.next} />
  ) : (
    <ArrowButton className={styles.prev} onClick={handleClick} />
  );
}

export function FeedbackSlider({ items, className }) {
  const [step, setStep] = useState(0);
  const swiperRef = useRef(null);
  const { isTablet } = useAdaptiveWidth();
  const slidesInView = isTablet ? 1 : 3;

  const sliderClassName = cn(styles.slider, className);

  if (!items || items.length === 0) return null;

  const totalPages = Math.ceil(items.length / slidesInView);
  const showButtons = items.length > slidesInView;

  return (
    <div className={styles.wrapper}>
      <Title>Hear it from our clients</Title>
      <Swiper
        className={sliderClassName}
        spaceBetween={20}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        speed={1000}
        slidesPerView={slidesInView}
        slidesPerGroup={slidesInView}
        effect="slide"
      >
        {items.map((item, index) => {
          return (
            <SwiperSlide key={`${item}-${index}`} className="custom-slide">
              <FeedbackCard data={item} />
            </SwiperSlide>
          );
        })}
        <div className={styles.footer}>
          {showButtons && (
            <div className={styles.buttons}>
              <SliderButton
                onChange={() => {
                  if (step === 0) {
                    return;
                  }
                  setStep((prev) => prev - 1);
                  swiperRef.current.slidePrev();
                }}
              />
              <div className={styles.pagination}>
                {Array.from({ length: totalPages }).map((_, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      swiperRef.current.slideTo(index * slidesInView)
                    }
                    className={cn(styles.bullet, {
                      [styles.bulletActive]: index === step,
                    })}
                  />
                ))}
              </div>
              <SliderButton
                onChange={() => {
                  if (step === totalPages - 1) {
                    return;
                  }
                  setStep((prev) => prev + 1);
                  swiperRef.current.slideNext();
                }}
                isNextButton
              />
            </div>
          )}
        </div>
      </Swiper>
    </div>
  );
}
