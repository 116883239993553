import cn from 'classnames';
import styles from './Table.module.scss';

// type = 'split' | 'solid'
export function Table({ columns, grid, type = 'split', className, children }) {
  const wrapperClassName = cn(
    styles.wrapper,
    {
      [styles.solid]: type === 'solid',
    },
    className,
  );

  return (
    <div className={wrapperClassName}>
      <div
        className={cn(styles.heading, {
          [styles.headingSolid]: type === 'solid',
        })}
        style={{ gridTemplateColumns: grid }}
      >
        {columns.map((column) => (
          <div key={column} className={styles.columnTitle}>
            {column}
          </div>
        ))}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
