import cn from 'classnames';
import styles from './BlogCard.module.scss';

export function BlogCard({
  className,
  mediaClass,
  image,
  video,
  tag,
  title,
  desc,
  handleClick,
}) {
  const wrapperClassName = cn(styles.wrapper, className);
  const mediaClassName = cn(styles.media, mediaClass);

  return (
    <div className={wrapperClassName} onClick={handleClick}>
      {image && (
        <div className={styles.mediaWrapper}>
          <img
            loading="lazy"
            src={image}
            alt="media"
            className={mediaClassName}
            width="100%"
            height="100%"
          />
        </div>
      )}
      {video && (
        <video
          src={video}
          className={mediaClassName}
          width="100%"
          height="100%"
        />
      )}
      <div className={styles.tag}>{tag}</div>
      <div className={styles.title}>{title}</div>
      <div className={styles.desc}>{desc}</div>
    </div>
  );
}
