import cn from 'classnames';
import styles from './TableCell.module.scss';

export function TableCell({ className, children, onClick }) {
  const wrapperClassName = cn(styles.wrapper, className);

  return (
    <div className={wrapperClassName} onClick={onClick}>
      {children}
    </div>
  );
}
