import React from 'react';
import { useController } from 'react-hook-form';
import styles from './Checkbox.module.scss';

export const Checkbox = ({ name, label }) => {
  const { field } = useController({ name });

  return (
    <div className={styles.checkboxContainer}>
      <input
        type="checkbox"
        id={name}
        {...field}
        className={styles.checkboxInput}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};
