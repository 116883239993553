import cn from 'classnames';
import styles from './Accordion.module.scss';
import { useRef, useState } from 'react';
import { DropdownIcon } from '@/lib';

function AccordionArrow({ isOpen }) {
  const arrowIconStyles = cn(styles.arrowIcon, {
    [styles.isOpen]: isOpen,
  });

  return (
    <div className={arrowIconStyles}>
      <DropdownIcon color="white" />
    </div>
  );
}

export function Accordion({ title, children, className }) {
  const accordionClassName = cn(styles.accordion, className);
  const [open, setOpen] = useState(false);
  const ContentRef = useRef(null);
  const defaultMaxHeight = open ? ContentRef.current?.scrollHeight : '0px';
  const [currentMaxHeight, setCurrentMaxHeight] = useState(defaultMaxHeight);

  const toggleOpening = () => {
    setOpen(!open);
    const content = ContentRef.current;
    if (content) {
      if (currentMaxHeight === '0px') {
        setCurrentMaxHeight(content.scrollHeight + 20 + 'px');
      } else {
        setCurrentMaxHeight('0px');
      }
    }
  };

  const listClassName = cn(styles.list, {
    [styles.hidden]: !open,
  });

  return (
    <div className={accordionClassName}>
      <div onClick={toggleOpening} className={styles.title}>
        {title}
        <div className={styles.icon}>
          <AccordionArrow isOpen={open} />
        </div>
      </div>
      <div
        className={listClassName}
        ref={ContentRef}
        style={{ maxHeight: currentMaxHeight }}
      >
        {children}
      </div>
    </div>
  );
}
