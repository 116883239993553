import { useEffect, useRef, useState } from 'react';
import { Button, Flex, BaseModal, Loader, Select } from '@/lib';
import { FormProvider, useForm } from 'react-hook-form';
import styles from './SwapModal.module.scss';
import toast from 'react-hot-toast';
import { fetchData } from '@/services/fetchData';
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from '@web3modal/ethers5/react';
import { initiateSwapNative, initiateSwapUSDT } from '@/services/Swap';
import { useConnectBahamut } from '@/hooks/useConnectBahamut';
import { getGlobalState } from '@/store';
import cn from 'classnames';
import { ethers } from 'ethers';
import { fromWei } from '@/services/blockchain';

export const SwapModal = ({ onClose }) => {
  const { isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const { chainId } = useWeb3ModalAccount();
  const { connect } = useConnectBahamut(chainId);

  useEffect(() => {
    if (!isConnected) {
      connect();
      onClose();
    }
  }, [isConnected, open, onClose, connect]);

  if (!isConnected) {
    return null;
  }

  return (
    <BaseModal
      onClose={onClose}
      title="Cross-Chain Swap"
      titleClassName={styles.screenTitle}
      width="460px"
    >
      <SwapScreen />
    </BaseModal>
  );
};

const getNetworksData = (networks) => {
  if (!networks) return [{ value: 'Loading', label: 'Loading' }];

  const networkEntries = Object.entries(networks ?? {}).map((item) => {
    return item[1];
  });

  const networksDataArr = networkEntries.map((item) => {
    return {
      value: item.displayName,
      label: item.displayName,
      icon: item.icon,
    };
  });

  return { networksDataArr, networkEntries };
};

function SwapScreen() {
  const availableNetworks = getGlobalState('appNetworks');
  const dropdownRef = useRef(null);
  const [selectActive, setSelectActive] = useState(false);
  const { networksDataArr, networkEntries } =
    getNetworksData(availableNetworks);
  const [currentNetwork, setCurrentNetwork] = useState(networkEntries[0]);
  const [currentToken, setCurrentToken] = useState(currentNetwork.tokens[0]);
  const [selectedNetwork, setSelectedNetwork] = useState(networksDataArr[0]);
  const [exchangeRate, setExchangeRate] = useState(0);
  const account = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loadingRate, setLoadingRate] = useState(false);
  const [blockForm, setBlockForm] = useState(false);
  const [valueSend, setValueSend] = useState('');
  const [valueReceive, setValueReceive] = useState('');
  const [balance, setBalance] = useState(0);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [minValueError, setMinValueError] = useState('');

  const methods = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    setValueReceive(Number(valueSend) / exchangeRate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeRate]);

  useEffect(() => {
    const network = availableNetworks[selectedNetwork.value];

    const fetchBalance = async () => {
      setLoadingBalance(true);

      if (account.address && network.rpcUrl) {
        const provider = new ethers.providers.JsonRpcProvider(network.rpcUrl);
        const balance = await provider.getBalance(account.address);
        const parsedBalance = fromWei(balance);
        setLoadingBalance(false);
        setBalance(parsedBalance);
      }
    };

    if (!loadingBalance) {
      fetchBalance();
    }

    setCurrentNetwork(network);
    setCurrentToken(network.tokens[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNetwork, availableNetworks, account.address]);

  const switchNetworkInit = async (network) => {
    try {
      toast.loading('Switching network...');

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network.chainIdHex }],
      });

      toast.dismiss();
      toast.success(`Network switched to ${network.displayName}`);
    } catch (error) {
      toast.dismiss();

      if (error.code && error.code === 4902) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: network.chainIdHex,
              chainName: network.name,
              nativeCurrency: {
                name: network.currency,
                symbol: network.currency,
                decimals: 18,
              },
              rpcUrls: [network.rpcUrl],
              blockExplorerUrls: [network.explorerUrl],
            },
          ],
        });
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: network.chainIdHex }],
        });

        return;
      }

      toast.dismiss();
      toast.error('Error while switching network');
    }
  };

  const onSubmit = async () => {
    if (account.chainId !== currentNetwork.chainId) {
      if (!window.ethereum) return;

      switchNetworkInit(currentNetwork);
      return;
    }

    if (valueSend < currentToken.minDeposit) {
      toast.error(
        `Minimal exchange value for ${currentToken.name} token is ${currentToken.minDeposit}`,
      );
      return;
    }

    try {
      setBlockForm(true);
      toast.loading('Processing...');
      let transaction;

      if (currentToken.name === 'USDT') {
        transaction = await initiateSwapUSDT({
          walletProvider,
          contractAddress: currentNetwork.swapContractAddress,
          valueSend,
          usdtContactAddress: currentToken.contract,
          decimals: currentToken.decimals,
        });
      } else {
        transaction = await initiateSwapNative({
          walletProvider,
          contractAddress: currentNetwork.swapContractAddress,
          valueSend,
        });
      }

      if (transaction.hash) {
        setValueSend(0);

        const swap = await fetchData({
          customUrl: `https://swap-backend.8legends.ai/swap/status/${transaction.hash}`,
        });

        if (swap.status === 'success') {
          toast.dismiss();
          toast.success(
            'Tokens successfully received and taken in processing 👌 Please wait a while',
          );

          setBlockForm(false);
        }
      }
    } catch (error) {
      toast.dismiss();
      toast.error(
        'Error while swap processing. Check your balance or try again later',
      );
      setBlockForm(false);
    }
  };

  const getExchangeRate = async (currency) => {
    setLoadingRate(true);

    const response = await fetchData({
      customUrl: `https://swap-backend.8legends.ai/swap/price/${currency}`,
    }).finally(() => setLoadingRate(false));

    if (response && response.price) {
      setExchangeRate(Number(response.price));
    } else {
      toast.error('Error getting exchange rate');
    }
  };

  useEffect(() => {
    if (valueSend && valueSend < currentToken.minDeposit) {
      setMinValueError(currentToken.minDeposit);
    } else {
      setMinValueError('');
    }
  }, [valueSend, currentToken]);

  useEffect(() => {
    getExchangeRate(currentToken.name);
  }, [currentToken]);

  const toggleSelect = () => {
    if (currentNetwork.tokens && currentNetwork.tokens.length > 1) {
      setSelectActive((prev) => !prev);
    }
  };

  const handleValueSendChange = (e) => {
    const { value } = e.target;
    const filteredValue = value.replace(/[^\d.]/g, '');
    setValueSend(filteredValue);
    setValueReceive(Number(filteredValue) / exchangeRate);
  };

  const handleMaxClick = () => {
    const filteredValue = balance.toString().replace(/[^\d.]/g, '');
    setValueSend(filteredValue);
    setValueReceive(Number(filteredValue) / exchangeRate);
  };

  const handleValueReceiveChange = (e) => {
    const { value } = e.target;
    const filteredValue = value.replace(/[^\d.]/g, '');
    setValueReceive(filteredValue);
    setValueSend(Number(filteredValue) * exchangeRate);
  };

  const handleSetToken = (token) => {
    setCurrentToken(token);
    toggleSelect();
  };

  const handleChangeSelectNetwork = (network) => {
    setSelectedNetwork(network);
    setSelectActive(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Flex vertical gap={24}>
            <p className={styles.disclaimer}>
              <span className={styles.disclaimerTitle}>Please note:</span>
              <span className={styles.disclaimerText}>
                Transaction processing may take a while. Feel free to contact
                support if tokens was not received for more than 30 minutes
              </span>
            </p>
            <Select
              options={networksDataArr}
              selected={selectedNetwork}
              onChange={handleChangeSelectNetwork}
              title="Select network"
            />
            <div className={styles.inputs}>
              <InputWithInnerLabel
                label="Send"
                value={valueSend}
                onInputChange={handleValueSendChange}
                title="Choose Token and Amount"
                showBalance
                balance={balance}
                minValueError={minValueError}
              >
                <div>
                  <div
                    className={cn(styles.networkSelect, {
                      [styles.optional]: currentNetwork.tokens.length > 1,
                    })}
                  >
                    {balance > 0 && (
                      <span className={styles.max} onClick={handleMaxClick}>
                        MAX
                      </span>
                    )}
                    <img
                      src={currentToken.icon ?? '/webp/empty.webp'}
                      alt="network"
                      className={styles.icon}
                      onClick={toggleSelect}
                    />
                    <span onClick={toggleSelect}>{currentToken.name}</span>
                    {currentNetwork.tokens &&
                      currentNetwork.tokens.length > 1 && (
                        <img
                          src="/svg/arrowRight.svg"
                          alt="arrow"
                          onClick={toggleSelect}
                          className={selectActive ? styles.up : styles.down}
                        />
                      )}
                  </div>
                  {selectActive && (
                    <div className={styles.dropdown} ref={dropdownRef}>
                      {currentNetwork.tokens.map((token, index) => (
                        <div
                          key={index}
                          className={styles.dropdownItem}
                          onClick={() => handleSetToken(token)}
                        >
                          <img
                            src={token.icon}
                            className={styles.icon}
                            alt="network"
                          />
                          <span>{token.name}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </InputWithInnerLabel>
              <InputWithInnerLabel
                label="Receive"
                value={
                  Number(valueSend) < 1
                    ? Number(valueReceive).toFixed(7)
                    : Number(valueReceive).toFixed(3)
                }
                onInputChange={handleValueReceiveChange}
                loading={loadingRate}
              >
                <Flex justify="flex-end" align="center" gap={6}>
                  <img loading="lazy" src="/svg/ftn.svg" alt="ftn" />
                  <span>FTN</span>
                </Flex>
              </InputWithInnerLabel>
            </div>

            <div className={styles.actions}>
              <Button
                color="white"
                type="submit"
                fullWidth
                disabled={blockForm}
                size="small"
              >
                {account.chainId !== currentNetwork.chainId
                  ? 'Switch network'
                  : 'Confirm'}
              </Button>
            </div>
          </Flex>
        </form>
      </FormProvider>
    </>
  );
}

function InputWithInnerLabel({
  label,
  value,
  onInputChange,
  children,
  loading,
  title,
  showBalance,
  balance,
  minValueError,
}) {
  const [isActive, setActive] = useState(false);

  return (
    <div className={styles.wrapper}>
      <span className={styles.inputTitle}>{title}</span>
      <div
        className={cn(styles.inputField, {
          [styles.inputFieldActive]: isActive,
          [styles.inputFieldError]: minValueError,
        })}
      >
        <span>{label}</span>
        <Flex justify="space-between" align="center" style={{ height: 40 }}>
          {loading ? (
            <Loader className={styles.loader} />
          ) : (
            <input
              value={value}
              onChange={onInputChange}
              placeholder={0}
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
              type="text"
              className={cn(styles.input, {
                [styles.inputError]: minValueError,
              })}
              min={0}
            />
          )}
          {children}
        </Flex>
        {!minValueError && showBalance && (
          <span className={styles.balance}>
            Balance: {balance === '0.0' ? 0 : balance.toString().slice(0, 7)}
          </span>
        )}
        {minValueError && (
          <span className={styles.valueError}>
            Min amount is {minValueError}
          </span>
        )}
      </div>
    </div>
  );
}
