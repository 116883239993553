export const TelegramIcon = ({
  color = 'black',
  innerColor = 'white',
  className,
  onClick,
  size,
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <g clipPath="url(#clip0_982_1374)">
        <path
          d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
          fill={color}
          style={{ transition: 'all 0.3s' }}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.07377 8.90502C6.69745 7.76193 8.44698 7.00833 9.32237 6.64423C11.8218 5.60464 12.3411 5.42406 12.6796 5.41809C12.7541 5.41678 12.9205 5.43523 13.0284 5.52273C13.1194 5.59661 13.1445 5.69641 13.1565 5.76646C13.1685 5.83651 13.1834 5.99608 13.1715 6.12076C13.0361 7.54387 12.45 10.9974 12.1519 12.5913C12.0257 13.2657 11.7773 13.4919 11.5368 13.514C11.0141 13.5621 10.6173 13.1686 10.1111 12.8368C9.31894 12.3175 8.87144 11.9943 8.10256 11.4876C7.21398 10.9021 7.79001 10.5802 8.29641 10.0543C8.42893 9.91661 10.7317 7.82204 10.7763 7.63203C10.7819 7.60827 10.787 7.51969 10.7344 7.47291C10.6818 7.42614 10.6041 7.44214 10.5481 7.45486C10.4686 7.47289 9.20322 8.30928 6.75183 9.96404C6.39264 10.2107 6.06731 10.3309 5.77581 10.3246C5.45447 10.3176 4.83633 10.1429 4.3768 9.99349C3.81317 9.81027 3.36522 9.71341 3.40422 9.40226C3.42454 9.24019 3.64772 9.07445 4.07377 8.90502Z"
          fill={innerColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_982_1374">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
