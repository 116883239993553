import cn from 'classnames';
import styles from './Breadcrumbs.module.scss';
import { useRouter } from 'next/router';
import Link from 'next/link';

export function Breadcrumbs({ routes, className }) {
  const router = useRouter();
  const paginationClassName = cn(styles.pagination, className);
  const getIsCurrentPage = (link) => {
    return (
      router.asPath === link ||
      `${router.asPath}/` === link ||
      router.asPath.split('?')[0] === link
    );
  };

  const handleRouteClick = (link) => {
    if (getIsCurrentPage(link)) {
      return;
    }

    if (Object.values(router.query)[0] === link) {
      return;
    }

    router.push(link);
  };

  return (
    <div className={paginationClassName}>
      <Link className={styles.route} href="/">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M21.5 19V12.8768C21.5 11.4536 21.5 10.742 21.3179 10.0857C21.1565 9.5044 20.8913 8.95707 20.535 8.4702C20.1328 7.92061 19.5742 7.47966 18.4572 6.59778L18.4572 6.59776L16.9572 5.41354C15.1862 4.01542 14.3007 3.31635 13.3207 3.04796C12.4562 2.81118 11.5438 2.81118 10.6793 3.04796C9.69929 3.31635 8.81381 4.01542 7.04285 5.41354L5.54285 6.59775C4.42577 7.47965 3.86724 7.9206 3.46504 8.4702C3.10875 8.95707 2.84351 9.5044 2.68215 10.0857C2.5 10.742 2.5 11.4536 2.5 12.8768V19C2.5 19.9319 2.5 20.3978 2.65224 20.7654C2.85523 21.2554 3.24458 21.6448 3.73463 21.8478C4.10218 22 4.56812 22 5.5 22H6.375C6.49106 22 6.5491 22 6.59814 21.9976C7.62554 21.9471 8.44712 21.1255 8.49759 20.0981C8.5 20.0491 8.5 19.9911 8.5 19.875V17C8.5 15.067 10.067 13.5 12 13.5C13.933 13.5 15.5 15.067 15.5 17V19.875C15.5 19.9911 15.5 20.0491 15.5024 20.0981C15.5529 21.1255 16.3745 21.9471 17.4019 21.9976C17.4509 22 17.5089 22 17.625 22H18.5C19.4319 22 19.8978 22 20.2654 21.8478C20.7554 21.6448 21.1448 21.2554 21.3478 20.7654C21.5 20.3978 21.5 19.9319 21.5 19Z"
              fill="white"
            />
          </g>
        </svg>{' '}
      </Link>
      {routes.map((route) => (
        <div key={route.link} className={styles.row}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            style={{ minWidth: 24 }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.37658 4.39052C7.87447 4.91122 7.87447 5.75544 8.37658 6.27614L13.896 12L8.37658 17.7239C7.87447 18.2446 7.87447 19.0888 8.37658 19.6095C8.87868 20.1302 9.69275 20.1302 10.1949 19.6095L16.6234 12.9428C17.1255 12.4221 17.1255 11.5779 16.6234 11.0572L10.1949 4.39052C9.69275 3.86983 8.87868 3.86983 8.37658 4.39052Z"
                fill="white"
              />
            </g>
          </svg>
          <span
            onClick={() => handleRouteClick(route.link)}
            className={`${styles.route} ${
              getIsCurrentPage(route.link) ? styles.nonClick : ''
            }`}
          >
            {route.text}
          </span>
        </div>
      ))}
    </div>
  );
}
