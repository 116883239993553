import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createGlobalState } from 'react-hooks-global-state';
import { APP_REDUCER_NAME, appReducer } from './app';

const { getGlobalState, useGlobalState, setGlobalState } = createGlobalState({
  connectedAccount: null,
  balance: 0,
  contract: null,
  redirect: null,
  platform: null,
  bidders: [],
  auctions: [],
  auction: null,
  group: null,
  appNetworks: {},
});

const store = configureStore({
  reducer: combineReducers({
    [APP_REDUCER_NAME]: appReducer,
  }),
});
export { store, getGlobalState, useGlobalState, setGlobalState };
