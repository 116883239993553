import { APP_REDUCER_NAME } from './constants';

export const selectMenuActive = (state) => state[APP_REDUCER_NAME].menuActive;

export const selectOwnedNFTs = (state) => state[APP_REDUCER_NAME].ownedNFTs;

export const selectCreatedNFTs = (state) => state[APP_REDUCER_NAME].createdNFTs;

export const selectOnSaleNFTs = (state) => state[APP_REDUCER_NAME].onSaleNFTs;

export const selectTransferList = (state) =>
  state[APP_REDUCER_NAME].transferList;

export const selectIsTransferMode = (state) =>
  state[APP_REDUCER_NAME].isTransferMode;

export const selectTransferApproval = (state) =>
  state[APP_REDUCER_NAME].transferApproval;
