export const MediumIcon = ({ color = 'black', className, onClick, size }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <path
        d="M24 11.8277C24 15.007 23.4672 17.5859 22.8097 17.5859C22.1522 17.5859 21.6196 15.0077 21.6196 11.8277C21.6196 8.64769 22.1524 6.06944 22.8097 6.06944C23.4669 6.06944 24 8.64746 24 11.8277Z"
        fill={color}
        style={{ transition: 'all 0.3s' }}
      />
      <path
        d="M20.9628 11.8277C20.9628 15.377 19.4476 18.2555 17.5784 18.2555C15.7092 18.2555 14.194 15.377 14.194 11.8277C14.194 8.27839 15.709 5.39995 17.5782 5.39995C19.4473 5.39995 20.9626 8.27747 20.9626 11.8277"
        fill={color}
        style={{ transition: 'all 0.3s' }}
      />
      <path
        d="M13.5374 11.8277C13.5374 15.5984 10.5069 18.6552 6.76884 18.6552C3.03073 18.6552 0 15.5977 0 11.8277C0 8.05767 3.0305 5 6.76884 5C10.5072 5 13.5374 8.05698 13.5374 11.8277Z"
        fill={color}
        style={{ transition: 'all 0.3s' }}
      />
    </svg>
  );
};
