/* eslint-disable no-console */
import { getGlobalState, setGlobalState } from '@/store';
import abi from '../abis/src/contracts/Auction.sol/Auction.json';
import { ethers } from 'ethers';
import toast from 'react-hot-toast';

const ContractAddress = process.env.CONTRACT_ADDRESS;
const ContractAbi = abi.abi;
let tx;

export const toWei = (num) => ethers.utils.parseEther(num?.toString());
export const fromWei = (num) =>
  num ? ethers.utils.formatEther(BigInt(num || 0)?.toString()) : num;
export const numberToDigits = (n, symbols) =>
  n ? n.toLocaleString('en', { maximumFractionDigits: symbols }) : String(n);

const showErrorToast = (identifier, error) => {
  toast.error(`Error in ${identifier}: ${error.message || error}`);
};

const getEthereumContract = async () => {
  try {
    const { ethereum } = window;
    const provider = await new ethers.providers.Web3Provider(ethereum);
    const signer = await provider.getSigner();
    const contract = await new ethers.Contract(
      ContractAddress,
      ContractAbi,
      signer,
    );

    await setGlobalState('contract', contract);
    return contract;
  } catch (e) {
    console.error(e);
  }
};

const disconnect = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_revokePermissions',
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
  } catch (e) {
    console.error(e);
  }

  // await web3.currentProvider.disconnect();
};

const isWallectConnected = async () => {
  try {
    const { ethereum } = window;
    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (!accounts?.length) {
      return false;
    }

    const balanceHash = await ethereum.request({
      method: 'eth_getBalance',
      params: [accounts[0], 'latest'],
    });
    const balance = parseInt(balanceHash, 16) / Math.pow(10, 18);
    await setGlobalState('balance', balance);
    if (typeof window !== 'undefined') {
      window.ethereum.on('accountsChanged', async () => {
        await isWallectConnected();
      });
    }

    if (accounts?.length) {
      // setGlobalState("connectedAccount", account);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const claimPrize = async ({ tokenId, id }) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      toast.warn('Please install Metamask');
      setTimeout(() => {
        window.open(
          'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-US&utm_source=ext_sidebar',
          '_blank',
        );
      }, 2000);
      return;
    }
    const connectedAccount = getGlobalState('connectedAccount');
    const contract = await getEthereumContract();
    tx = await contract.claimPrize(tokenId, id, {
      from: connectedAccount,
    });
    await tx.wait();
    await getBidders(tokenId);
  } catch (error) {
    showErrorToast('claimPrize', error);
  }
};

const getBidders = async (id) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      toast.warn('Please install Metamask');
      setTimeout(() => {
        window.open(
          'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-US&utm_source=ext_sidebar',
          '_blank',
        );
      }, 2000);
      return;
    }
    const contract = await getEthereumContract();
    const bidders = await contract.getBidders(id);
    setGlobalState('bidders', structuredBidders(bidders));
  } catch (error) {
    showErrorToast('getBidders', error);
  }
};

const structuredBidders = (bidders) =>
  bidders
    ?.map((bidder) => ({
      timestamp: Number(bidder.timestamp + '000'),
      bidder: bidder.bidder.toLowerCase(),
      //price: BigNumber.from(bidder.price?._hex)?.toString(),
      price: fromWei(bidder.price),
      refunded: bidder.refunded,
      won: bidder.won,
    }))
    .sort((a, b) => b.price - a.price);

export { isWallectConnected, getBidders, claimPrize, disconnect };
