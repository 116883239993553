import cn from 'classnames';
import styles from './Tab.module.scss';

export function Tab({ className, text, active, onClick }) {
  const wrapperClassName = cn(
    styles.tab,
    {
      [styles.active]: active,
    },
    className,
  );

  return (
    <div className={wrapperClassName} onClick={onClick}>
      {text}
    </div>
  );
}
