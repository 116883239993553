import cn from 'classnames';
import styles from './SolidTableRow.module.scss';

export function SolidTableRow({ grid, className, even, children, onClick }) {
  const wrapperClassName = cn(
    styles.wrapper,
    {
      [styles.even]: even,
    },
    className,
  );

  return (
    <div
      className={wrapperClassName}
      style={{ gridTemplateColumns: grid }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
