import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDisconnect, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import Link from 'next/link';
import styles from './HeaderUserAvatar.module.scss';
import { Button } from '@/lib/Button';
import { truncate } from '@/utils/truncate';
import { Loader, ProfileIcon } from '@/lib';
import { getGlobalState, useGlobalState } from '@/store';
import { SwapModal } from '@/components/SwapModal';

export const HeaderUserAvatar = () => {
  const [userCard, setUserCard] = useState(false);
  const [swapModalOpen, setSwapModalOpen] = useState(false);
  const containerRef = useRef(null);
  const { disconnect } = useDisconnect();
  const [loading, setLoading] = useState(true);
  const [isClient, setIsClient] = useState(false);
  const balance = getGlobalState('balance');
  const [connectedAccount] = useGlobalState('connectedAccount');
  const { chainId } = useWeb3ModalAccount();

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (connectedAccount && isClient) {
      setLoading(false);
    }
  }, [connectedAccount, isClient]);

  const handleDisconnect = useCallback(async () => {
    try {
      disconnect().then(() => {
        window.location.reload();
      });
    } catch (e) {
      return e;
    }
  }, [disconnect]);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setUserCard(false);
    }
  };

  const renderBalance = (balance) => {
    const tmp = String(balance);
    if (tmp?.length > 10) {
      return tmp.slice(0, 10).concat('...');
    }
    return tmp;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <div
        className={styles.container}
        onMouseOver={() => setUserCard(true)}
        onMouseLeave={() => setUserCard(false)}
      >
        <div className={styles.userAvatarContainer}>
          <img
            loading="lazy"
            src="/svg/ftnWhite.svg"
            alt="user avatar"
            width={36}
            height={36}
          />
          <p className={styles.accountText}>
            {truncate(connectedAccount, 4, 4, 11)}
          </p>
        </div>
        {userCard && (
          <div className={styles.userCardWrapper}>
            <div className={styles.userCard} ref={containerRef}>
              <div className={styles.userCardContent}>
                <div className={styles.balanceInfo}>
                  <div className={styles.balanceRow}>
                    <p className={styles.balanceLabel}>Balance</p>
                    <div className={styles.accountBadge}>
                      <p className={styles.accountText}>
                        {truncate(connectedAccount, 4, 4, 11)}
                      </p>
                      <div className={styles.statusIndicator} />
                    </div>
                  </div>
                  <div className={styles.cryptoInfo}>
                    <img
                      src="/svg/ftn.svg"
                      className={styles.cryptoIcon}
                      alt="crypto icon"
                    />
                    <p className={styles.balanceAmount}>
                      {Number(balance) === 0
                        ? 0
                        : renderBalance(balance.toFixed(5))}
                    </p>
                    <p className={styles.balanceCurrency}>
                      {chainId === 5165 ? 'FTN' : 'Tokens'}
                    </p>
                  </div>
                  <Button
                    color="white"
                    className={styles.swapButton}
                    onClick={() => {
                      setSwapModalOpen(true);
                      setUserCard(false);
                    }}
                    size="small"
                  >
                    Cross-Chain Swap
                  </Button>
                </div>
                <div className={styles.actionLinks}>
                  <Link
                    className={styles.profileLink}
                    onClick={() => {
                      setUserCard(false);
                    }}
                    href="/profile"
                  >
                    <div className={styles.profileIconWrapper}>
                      <ProfileIcon className={styles.profileIcon} size={18} />
                    </div>
                    <p className={styles.linkText}>My profile</p>
                  </Link>
                  <div
                    className={styles.disconnectLink}
                    onClick={handleDisconnect}
                  >
                    <div className={styles.disconnectIconWrapper}>
                      <img
                        src="/svg/link.svg"
                        className={styles.disconnectIcon}
                        alt="disconnect icon"
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <p className={styles.linkText}>Disconnect</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {swapModalOpen && <SwapModal onClose={() => setSwapModalOpen(false)} />}
    </>
  );
};
