import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { defaultConfig } from '@web3modal/ethers5';
import { createWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import Head from 'next/head';
import { ScrollToTop } from '@/components/ScrollToTop';
import styles from './App.module.scss';
import '../styles/globals.scss';
import { setGlobalState, store } from '@/store';
import { isWallectConnected } from '@/services/blockchain';
import { Provider } from 'react-redux';
import { useRouter } from 'next/router';
import cn from 'classnames';
import {
  AVAX_CCHAIN_CHAIN_EXPLORER,
  AVAX_CCHAIN_RPC_URL,
  BAHAMUT_MAINNET_CHAIN_EXPLORER,
  BAHAMUT_MAINNET_RPC_URL,
  BNB_SMART_CHAIN_EXPLORER,
  BNB_SMART_RPC_URL,
  POLYGON_MAINNET_CHAIN_EXPLORER,
  POLYGON_MAINNET_RPC_URL,
} from '@/constants';
import { fetchData } from '@/services/fetchData';
import { Header } from '@/components/Header';
import { Footer } from '@/components/Footer';

const projectId = process.env.WALLET_CONNECT_PROJECT_ID;

const CHAIN_ID = Number(process.env.CHAIN_ID);

const BAHAMUT_MAINNET = {
  chainId: CHAIN_ID,
  name: process.env.NETWORK_NAME,
  currency: 'FTN',
  explorerUrl: BAHAMUT_MAINNET_CHAIN_EXPLORER,
  rpcUrl: BAHAMUT_MAINNET_RPC_URL,
};

const POLYGON_MAINNET = {
  chainId: 137,
  name: 'Polygon Mainnet',
  currency: 'POL',
  explorerUrl: POLYGON_MAINNET_CHAIN_EXPLORER,
  rpcUrl: POLYGON_MAINNET_RPC_URL,
};

const AVAX_CCHAIN_MAINNET = {
  chainId: 43114,
  name: 'Avalanche C-Chain',
  currency: 'AVAX',
  explorerUrl: AVAX_CCHAIN_CHAIN_EXPLORER,
  rpcUrl: AVAX_CCHAIN_RPC_URL,
};

const BNB_SMART_CHAIN_MAINNET = {
  chainId: 56,
  name: 'BNB Smart Chain Mainnet',
  currency: 'BNB',
  explorerUrl: BNB_SMART_CHAIN_EXPLORER,
  rpcUrl: BNB_SMART_RPC_URL,
};

const metadata = {
  name: '8 Legends',
  description:
    'Decentralized marketplace for story-based NFTs. Create, trade and collect interactive NFTs. Powered by Bahamut blockchain.',
  icons: [
    'https://gateway.pinata.cloud/ipfs/QmXtGMyAy3PHqdVufDjNCkAUt6iyVdswo6H4LsuuEntFQ3',
  ],
};

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
});

createWeb3Modal({
  ethersConfig,
  chains: [
    BAHAMUT_MAINNET,
    POLYGON_MAINNET,
    AVAX_CCHAIN_MAINNET,
    BNB_SMART_CHAIN_MAINNET,
  ],
  projectId,
  enableAnalytics: false,
  cacheProvider: false,
});

export default function App({ Component, pageProps }) {
  const { address } = useWeb3ModalAccount();
  const [userAgent, setUserAgent] = useState(null);
  const router = useRouter();
  const availableRoute = router.pathname !== '/not-available';
  const { chainId } = useWeb3ModalAccount();

  useEffect(() => {
    const fetchNetworks = async () => {
      const response = await fetchData({
        customUrl: 'https://swap-backend.8legends.ai/networks',
      });

      if (response) {
        setGlobalState('appNetworks', response);
      }
    };

    fetchNetworks();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const agent = window.navigator.userAgent.toLowerCase();
      setUserAgent(agent);
    }
  }, []);

  useEffect(() => {
    const connectApp = async (address) => {
      setGlobalState('connectedAccount', address);

      if (window.ethereum) {
        const balanceHash = await window.ethereum.request({
          method: 'eth_getBalance',
          params: [address, 'latest'],
        });
        const balance = parseInt(balanceHash, 16) / Math.pow(10, 18);
        setGlobalState('balance', balance);
      }
    };

    if (address && chainId) {
      connectApp(address);
    }
  }, [address, chainId]);

  const handlePlatform = (userAgent) => {
    if (/iPad|iPhone|iPod/i.test(userAgent)) {
      setGlobalState('platform', 'ios');
    } else if (/android/i.test(userAgent)) {
      setGlobalState('platform', 'android');
    } else {
      setGlobalState('platform', 'web');
    }
  };

  useEffect(() => {
    handlePlatform(userAgent);
    try {
      isWallectConnected();
    } catch (error) {
      return error;
    }
  }, [userAgent]);

  return (
    <>
      <Head>
        <title>8 Legends</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>

      <Provider store={store}>
        <main className={styles.main}>
          <div className={styles.appContainer}>
            {availableRoute && <Header />}
            <div
              className={cn(styles.pageContainer, {
                [styles.mainPage]: router.asPath === '/',
              })}
            >
              <Component {...pageProps} />
            </div>
            <Toaster
              toastOptions={{
                error: {
                  duration: 5000,
                },
                success: {
                  duration: 5000,
                },
              }}
            />
          </div>
          {availableRoute && <Footer />}
        </main>
      </Provider>
      <ScrollToTop />
    </>
  );
}
