import { Icon } from '@/lib';
import { Button } from '../Button';
import styles from './SocialMediaButton.module.scss';

export function SocialMediaButton({ url, type }) {
  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <Button
      variant="filled"
      color="gray"
      onClick={handleClick}
      className={styles.button}
    >
      <Icon
        type={type}
        color="#fff"
        innerColor="#000"
        className={styles.icon}
      />
    </Button>
  );
}
