export const LinkedinIcon = ({ color = 'black', className, onClick, size }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <g clipPath="url(#clip0_402_12398)">
        <path
          d="M16.6712 0H1.32875C0.976344 0 0.638371 0.139993 0.389182 0.389182C0.139993 0.638371 0 0.976344 0 1.32875V16.6712C0 17.0237 0.139993 17.3616 0.389182 17.6108C0.638371 17.86 0.976344 18 1.32875 18H16.6712C17.0237 18 17.3616 17.86 17.6108 17.6108C17.86 17.3616 18 17.0237 18 16.6712V1.32875C18 0.976344 17.86 0.638371 17.6108 0.389182C17.3616 0.139993 17.0237 0 16.6712 0ZM5.365 15.3337H2.65875V6.7375H5.365V15.3337ZM4.01 5.54625C3.70302 5.54452 3.40344 5.4519 3.14905 5.28007C2.89466 5.10823 2.69688 4.8649 2.58066 4.58077C2.46444 4.29664 2.43499 3.98444 2.49602 3.68359C2.55706 3.38274 2.70585 3.10671 2.92362 2.89034C3.14138 2.67396 3.41836 2.52695 3.7196 2.46785C4.02084 2.40874 4.33283 2.4402 4.61621 2.55824C4.89959 2.67628 5.14165 2.87563 5.31185 3.13111C5.48204 3.38659 5.57274 3.68677 5.5725 3.99375C5.5754 4.19928 5.53688 4.40328 5.45926 4.5936C5.38163 4.78393 5.26649 4.95668 5.12068 5.10155C4.97487 5.24643 4.80139 5.36046 4.61057 5.43686C4.41975 5.51326 4.2155 5.55047 4.01 5.54625ZM15.34 15.3412H12.635V10.645C12.635 9.26 12.0463 8.8325 11.2863 8.8325C10.4838 8.8325 9.69625 9.4375 9.69625 10.68V15.3412H6.99V6.74375H9.5925V7.935H9.6275C9.88875 7.40625 10.8038 6.5025 12.2 6.5025C13.71 6.5025 15.3412 7.39875 15.3412 10.0238L15.34 15.3412Z"
          fill={color}
          style={{ transition: 'all 0.3s' }}
        />
      </g>
      <defs>
        <clipPath id="clip0_402_12398">
          <rect
            width="18"
            height="18"
            fill={color}
            style={{ transition: 'all 0.3s' }}
          />
        </clipPath>
      </defs>
    </svg>
  );
};
