import cn from 'classnames';
import styles from './FeedbackCard.module.scss';
import { useEffect, useState } from 'react';
import { XIcon, LinkedinIcon } from '../../lib';

export function FeedbackCard({ data, className }) {
  const wrapperClassName = cn(styles.wrapper, className);
  const [hovered, setHovered] = useState(false);
  const [message, setMessage] = useState('');

  const {
    image_url: image,
    author_name: name,
    author_job_position: about,
    author_avatar: avatar,
    title: tag,
    author_linkedin: linkedIn,
    author_twitter: xcom,
  } = data;

  useEffect(() => {
    setMessage(data.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLinkClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className={wrapperClassName}>
      <div
        className={styles.content}
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <img
          src={image ?? '/webp/empty.webp'}
          alt="media"
          className={styles.media}
          width="100%"
          height="100%"
        />
        <p
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <div className={styles.profile}>
          <img
            src={avatar ?? '/webp/empty.webp'}
            alt="author"
            className={styles.avatar}
            width="100%"
            height="100%"
          />
          <div className={styles.userInfo}>
            <span className={styles.name}>{name}</span>
            <span className={styles.about}>{about}</span>
            <div className={styles.links}>
              {/* <TelegramIcon
                color={hovered ? 'black' : ' white'}
                innerColor={hovered ? 'white' : ' black'}
                className={styles.icon}
              /> */}
              {xcom && (
                <XIcon
                  color={hovered ? 'black' : ' white'}
                  className={styles.icon}
                  onClick={() => handleLinkClick(xcom)}
                />
              )}
              {linkedIn && (
                <LinkedinIcon
                  color={hovered ? 'black' : ' white'}
                  className={styles.icon}
                  onClick={() => handleLinkClick(linkedIn)}
                />
              )}
              {/* <FacebookIcon
                color={hovered ? 'black' : ' white'}
                className={styles.icon}
              />
              <InstagramIcon
                color={hovered ? 'black' : ' white'}
                className={styles.icon}
              /> */}
            </div>
          </div>
        </div>
        <div className={styles.tag}>{tag}</div>
      </div>
    </div>
  );
}
