export const FacebookIcon = ({ color = 'black', className, onClick, size }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.376 8.97787C16.376 4.84175 13.0434 1.48877 8.93238 1.48877C4.82138 1.48877 1.48877 4.84175 1.48877 8.97787C1.48877 12.7159 4.21079 15.8142 7.76931 16.376V11.1427H5.87934V8.97787H7.76931V7.32793C7.76931 5.45097 8.88059 4.4142 10.5809 4.4142C11.3953 4.4142 12.2471 4.56047 12.2471 4.56047V6.40349H11.3085C10.3838 6.40349 10.0954 6.98078 10.0954 7.57303V8.97787H12.1599L11.8299 11.1427H10.0954V16.376C13.654 15.8142 16.376 12.7159 16.376 8.97787Z"
        fill={color}
        style={{ transition: 'all 0.3s' }}
      />
    </svg>
  );
};
