import { forwardRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

export const Button = forwardRef(
  (
    {
      children,
      size = 'big', // small | big
      variant = 'filled', // filled | outline | ghost
      color = 'white', // white | black | blue | orange | gray | graphite
      onClick,
      disabled,
      className,
      type = 'button',
      tabIndex,
      icon,
      fullWidth,
      onMouseOver,
      onMouseLeave,
      noShadow,
    },
    ref,
  ) => {
    const buttonClassName = cn(
      styles.button,
      {
        [styles.outline]: variant === 'outline',
        [styles.ghost]: variant === 'ghost',
        [styles.gray]: color === 'gray',
        [styles.black]: color === 'black',
        [styles.orange]: color === 'orange',
        [styles.graphite]: color === 'graphite',
        [styles.blue]: color === 'blue',
        [styles.small]: size === 'small',
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
        [styles.noShadow]: noShadow,
      },
      className,
    );

    const handleClick = (event) => {
      if (disabled || !onClick) return;
      onClick(event);
    };

    return (
      <button
        className={buttonClassName}
        onClick={(e) => handleClick(e)}
        type={type}
        tabIndex={tabIndex}
        ref={ref}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        {children}
        {icon && (
          <img loading="lazy" src={icon} alt="icon" className={styles.icon} />
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';
Button.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'big']),
  variant: PropTypes.oneOf(['filled', 'outline', 'ghost']),
  color: PropTypes.oneOf([
    'white',
    'black',
    'blue',
    'orange',
    'gray',
    'graphite',
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  tabIndex: PropTypes.number,
  icon: PropTypes.string,
};
