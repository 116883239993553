import toast from 'react-hot-toast';

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
}

export const copyToClipboard = (text) => {
  if (navigator.clipboard) {
    toast.success('Successfully copied to clipboard');
    return navigator.clipboard.writeText(text);
  }
  toast.success('Successfully copied to clipboard');
  return fallbackCopyTextToClipboard(text);
};
