import React, { useState } from 'react';
import styles from './Tabs.module.scss';
import { Tab } from './Tab';

export const Tabs = ({ tabs, onChange }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index, tab) => {
    setActiveTab(index);
    onChange(tab.value);
  };

  return (
    <div className={styles.tabsContainer}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          active={activeTab === index}
          text={tab.title}
          onClick={() => handleTabClick(index, tab)}
        />
      ))}
    </div>
  );
};
