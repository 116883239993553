import { MOBILE_RESOLUTION, TABLET_RESOLUTION } from '@/constants';
import { useWindowWidth } from './useWindowWidth';

export const useAdaptiveWidth = () => {
  const width = useWindowWidth();
  const isTablet = width && width < TABLET_RESOLUTION;
  const isMobile = width && width < MOBILE_RESOLUTION;

  return { isTablet, isMobile };
};
