import { FacebookIcon } from './FacebookIcon';
import { InstagramIcon } from './InstagramIcon';
import { LinkedinIcon } from './LinkedinIcon';
import { TelegramIcon } from './TelegramIcon';
import { XIcon } from './XIcon';
import { WebsiteIcon } from './WebsiteIcon';
import { ArrowIcon } from './ArrowIcon';
import { ShareIcon } from './ShareIcon';
import { ThreeDotsIcon } from './ThreeDotsIcon';
import { SearchIcon } from './SearchIcon';
import { MediumIcon } from '.';

export const Icon = ({ type, className, color, innerColor, onClick, size }) => {
  switch (type) {
    case 'facebook':
      return (
        <FacebookIcon
          className={className}
          color={color}
          onClick={onClick}
          size={size}
        />
      );
    case 'instagram':
      return (
        <InstagramIcon
          className={className}
          color={color}
          onClick={onClick}
          size={size}
        />
      );
    case 'linkedIn':
      return (
        <LinkedinIcon
          className={className}
          color={color}
          onClick={onClick}
          size={size}
        />
      );
    case 'telegram':
      return (
        <TelegramIcon
          className={className}
          color={color}
          innerColor={innerColor}
          onClick={onClick}
          size={size}
        />
      );
    case 'xcom':
      return (
        <XIcon
          className={className}
          color={color}
          onClick={onClick}
          size={size}
        />
      );
    case 'arrow':
      return (
        <ArrowIcon
          className={className}
          color={color}
          onClick={onClick}
          size={size}
        />
      );
    case 'share':
      return (
        <ShareIcon
          className={className}
          color={color}
          onClick={onClick}
          size={size}
        />
      );
    case 'threeDots':
      return (
        <ThreeDotsIcon
          className={className}
          color={color}
          onClick={onClick}
          size={size}
        />
      );
    case 'medium':
      return (
        <MediumIcon
          className={className}
          color={color}
          onClick={onClick}
          size={size}
        />
      );
    case 'search':
      return (
        <SearchIcon
          className={className}
          color={color}
          onClick={onClick}
          size={size}
        />
      );
    default:
      return (
        <WebsiteIcon
          className={className}
          color={color}
          onClick={onClick}
          size={size}
        />
      );
  }
};
