import cn from 'classnames';
import styles from './Input.module.scss';
import { useRef } from 'react';
import { Button, Flex } from '..';
import { useController, useFormContext } from 'react-hook-form';
import { Alert } from '../Alert';

export function Input({
  label,
  placeholder,
  required,
  maxLength,
  minLength,
  pattern,
  button,
  defaultValue,
  type = 'text',
  name,
  className,
  contentClassName,
  disabled,
  content,
  readonly,
  description,
  alert,
}) {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    rules: {
      required: { value: required, message: 'required' },
      maxLength: { value: maxLength, message: 'maxLength' },
      minLength: { value: minLength, message: 'minLength' },
      pattern: { value: pattern, message: 'pattern' },
    },
    defaultValue,
    control,
  });

  const error = fieldState.error ? fieldState.error.message : '';
  const wrapperClassName = cn(styles.wrapper, className);
  const inputClassName = cn(styles.inputField, {
    [styles.error]: error,
    [styles.disabled]: disabled,
    [styles.readonly]: readonly,
  });
  const contentClass = cn(
    styles.insideContent,
    {
      [styles.disabled]: disabled,
    },
    contentClassName,
  );

  // const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef(null);

  const handleChange = (e) => {
    let inputValue = e.target.value;

    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.slice(0, maxLength);
      return;
    }

    field.onChange(e);
  };

  const handleContainerClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Flex vertical className={wrapperClassName}>
      <div className={styles.header}>
        {label && (
          <Flex gap={4}>
            <p className={styles.label}>{label}</p>
            {required && <span className={styles.required}>*</span>}
          </Flex>
        )}
        {button && (
          <Button
            text={button.text}
            onClick={button.onClick}
            variant="ghost"
            className={styles.button}
            tabIndex={-1}
            type="button"
          />
        )}
      </div>
      <Flex
        align="center"
        className={inputClassName}
        onClick={handleContainerClick}
      >
        <input
          {...field}
          ref={inputRef}
          onChange={handleChange}
          onBlur={field.onBlur}
          placeholder={placeholder} // {showPassword ? '' : placeholder}
          type={type} // {type === 'password' && showPassword ? 'text' : type}
        />
        <Flex flex="0" className={contentClass}>
          {content}
        </Flex>
        {/* currently not used, TODO: remove if no such inputs required
        {type === 'password' && (
          <img
            src={showPassword ? '/svg/eye-closed.svg' : '/svg/eye.svg'}
            alt="show-password"
            className={styles.showPassword}
            onClick={() => setShowPassword((prev) => !prev)}
          />
        )} */}
      </Flex>
      {error ? (
        <p className={styles.errorTag}>{handleError(type, error)}</p>
      ) : (
        <>
          {description && <BottomLabel description={description} />}
          {alert && <Alert text={alert} />}
        </>
      )}
    </Flex>
  );
}

function BottomLabel({ description }) {
  return <p className={styles.description}>{description}</p>;
}

function handleError(type, error) {
  switch (error) {
    case 'required':
      return 'This field is required';
    case 'pattern':
      if (type === 'password')
        return 'The password must contain numbers, Latin letters, and be at least 8 characters long';
      if (type === 'e-mail') return 'Invalid email format';
      if (type === 'tel') return 'Invalid phone number format';
      if (type === 'datepicker')
        return 'Date of birth cannot be later than today';
      return 'Invalid field format';
    case 'exists':
      return 'A user with this email already exists';
    case 'notFound':
      if (type === 'e-mail') return 'User with this email not found';
      break;
    case 'validate':
      return '';
    case 'mismatch':
      return 'Passwords do not match';
    default:
      return 'Error in filling out the field';
  }
}
