export const DropdownIcon = ({ color = 'black', className, onClick, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6095 16.6234C19.0888 17.1255 18.2446 17.1255 17.7239 16.6234L12 11.104L6.27614 16.6234C5.75544 17.1255 4.91122 17.1255 4.39052 16.6234C3.86982 16.1213 3.86982 15.3073 4.39052 14.8051L11.0572 8.37658C11.5779 7.87447 12.4221 7.87447 12.9428 8.37658L19.6095 14.8051C20.1302 15.3073 20.1302 16.1213 19.6095 16.6234Z"
          fill={color}
          style={{ transition: 'all 0.3s' }}
        />
      </g>
    </svg>
  );
};
