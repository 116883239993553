export const BACKEND_ADDRESS = process.env.BACKEND_ADDRESS;
export const SPACE = process.env.SPACE;

export const MOBILE_RESOLUTION = 435;
export const TABLET_RESOLUTION = 768;

export const BAHAMUT_MAINNET_RPC_URL = process.env.RPC_URL;
export const BAHAMUT_MAINNET_CHAIN_EXPLORER =
  process.env.NEXT_PUBLIC_CHAIN_EXPLORER;

export const POLYGON_MAINNET_RPC_URL = 'https://polygon.llamarpc.com';
export const POLYGON_MAINNET_CHAIN_EXPLORER = 'https://polygonscan.com';

export const AVAX_CCHAIN_RPC_URL = 'https://avalanche.drpc.org';
export const AVAX_CCHAIN_CHAIN_EXPLORER =
  'https://subnets.avax.network/c-chain';

export const BNB_SMART_RPC_URL = 'https://binance.llamarpc.com';
export const BNB_SMART_CHAIN_EXPLORER = 'https://bscscan.com';

export const TRANSFER_CONTRACT_ADDRESS = process.env.TRANSFER_CONTRACT_ADDRESS;

export const TELEGRAM_URL = 'https://t.me/ArtLegends8';
export const XCOM_URL = 'https://x.com/ArtLegends8';
export const MEDIUM_URL = 'https://medium.com/@8legends';

export const PRIMARY_COLOR = '#fe7762';
export const WHITE_COLOR = '#ffffff';
export const BLACK_COLOR = '#0e0e0e';
