import cn from 'classnames';
import styles from './Sphere.module.scss';

export const Sphere = ({ size, className }) => {
  if (size === 'small')
    return (
      <img
        src="/webp/bannerSmall.webp"
        className={cn(styles.smallSphere, className)}
        alt="background"
        width="360px"
        height="420px"
      />
    );

  return (
    <img
      src="/webp/bannerBig.webp"
      className={cn(styles.bigSphere, className)}
      alt="background"
      width="840px"
      height="800px"
    />
  );
};
