import React, { useCallback, useEffect, useState } from 'react';
import { HeaderUserAvatar } from './HeaderUserAvatar';
import { useDisconnect, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styles from './Header.module.scss';
import { Button } from '@/lib/Button';
import { useGlobalState } from '@/store';
import cn from 'classnames';
import { MEDIUM_URL, TELEGRAM_URL, XCOM_URL } from '@/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectMenuActive, setMenuActive } from '@/store/app';
import { Divider, ProfileIcon, RefreshIcon, SocialMediaButton } from '@/lib';
import { ContactUsModal } from '../ContactUsModal';
import { SwapModal } from '../SwapModal';
import { useConnectBahamut } from '@/hooks/useConnectBahamut';
import { useAdaptiveWidth } from '@/hooks/useAdaptiveWidth';

export const Header = () => {
  const router = useRouter();
  const [connectedAccount] = useGlobalState('connectedAccount');
  const menuActive = useSelector(selectMenuActive);
  const dispatch = useDispatch();
  const [contactUsModalOpen, setContactUsModalOpen] = useState(false);
  const [swapModalOpen, setSwapModalOpen] = useState(false);
  const { disconnect } = useDisconnect();
  const { chainId } = useWeb3ModalAccount();
  const { connect } = useConnectBahamut(chainId);
  const [hovered, setHovered] = useState(false);
  const { isTablet } = useAdaptiveWidth();

  const handleDisconnect = useCallback(async () => {
    try {
      disconnect().then(() => {
        window.location.reload();
      });
    } catch (e) {
      return e;
    }
  }, [disconnect]);

  useEffect(() => {
    if (menuActive) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = '';
    }
  }, [menuActive]);

  const handleMenuToggle = () => {
    dispatch(setMenuActive(!menuActive));
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.mainContainer}>
          <div className={styles.leftContainer}>
            <Link href="/" className={styles.logo}>
              <img
                loading="lazy"
                src="/webp/logo.webp"
                alt="logo"
                className={styles.img}
              />
            </Link>
            <nav className={styles.navLinks}>
              <Link
                href="/"
                className={cn(styles.navLink, {
                  [styles.navLinkActive]: router.asPath === '/',
                })}
              >
                Home
              </Link>
              <Link
                href="/explore"
                className={cn(styles.navLink, {
                  [styles.navLinkActive]: router.asPath === '/explore',
                })}
              >
                Explore
              </Link>
              <Link
                href="/create"
                className={cn(styles.navLink, {
                  [styles.navLinkActive]: router.asPath === '/create',
                })}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (connectedAccount) {
                    router.push('/create');
                  } else {
                    connect();
                  }
                }}
              >
                Create NFT
              </Link>
              <Link
                href="/blog"
                className={cn(styles.navLink, {
                  [styles.navLinkActive]: router.asPath === '/blog',
                })}
              >
                Blog
              </Link>
              <Link
                href="/about"
                className={cn(styles.navLink, {
                  [styles.navLinkActive]: router.asPath === '/about',
                })}
              >
                About
              </Link>
              {/* <Link
                href="/careers"
                className={cn(styles.navLink, {
                  [styles.navLinkActive]: router.asPath === '/careers',
                })}
              >
                Careers
              </Link> */}
            </nav>
          </div>
          <div className={styles.rightContainer}>
            <Button
              onClick={() => setSwapModalOpen(true)}
              color="orange"
              variant="filled"
              className={styles.storylinesButton}
              onMouseOver={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <span>Cross-Chain Swap</span>
              <RefreshIcon color={hovered ? '#fe7762' : '#ffffff'} />
            </Button>
            {connectedAccount ? (
              <HeaderUserAvatar />
            ) : (
              <Button
                color="blue"
                className={styles.connectWalletButton}
                onClick={() => connect()}
              >
                <ProfileIcon className={styles.profileIcon} />
              </Button>
            )}
            {swapModalOpen && (
              <SwapModal onClose={() => setSwapModalOpen(false)} />
            )}
          </div>
        </div>
      </header>
      <header className={styles.headerMobile}>
        <Link href="/" className={styles.logo}>
          <img
            loading="lazy"
            src="/webp/logo.webp"
            alt="logo"
            className={styles.img}
          />
        </Link>
        <div className={styles.headerMobileRight}>
          <Button
            onClick={() => setSwapModalOpen(true)}
            color="orange"
            variant="filled"
            className={styles.storylinesButton}
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            size={isTablet ? 'small' : undefined}
          >
            <span>Swap</span>
            <RefreshIcon color={hovered ? '#fe7762' : '#ffffff'} />
          </Button>
          <div className={styles.burger} onClick={handleMenuToggle}>
            <img
              src={menuActive ? '/svg/close.svg' : '/svg/burger.svg'}
              alt="burger-menu"
              className={styles.burgerIcon}
            />
          </div>
        </div>
      </header>
      {menuActive && (
        <div className={styles.menu}>
          <div>
            <div className={styles.menuHeader}>
              <Link href="/" className={styles.logo}>
                <img
                  loading="lazy"
                  src="/webp/logo.webp"
                  alt="logo"
                  className={styles.img}
                />
              </Link>
              <div className={styles.headerMobileRight}>
                <Button
                  onClick={() => setSwapModalOpen(true)}
                  color="orange"
                  variant="filled"
                  className={styles.storylinesButton}
                  onMouseOver={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  size={isTablet ? 'small' : undefined}
                >
                  <span>Swap</span>
                  <RefreshIcon color={hovered ? '#fe7762' : '#ffffff'} />
                </Button>
                <div className={styles.burger} onClick={handleMenuToggle}>
                  <img
                    src={menuActive ? '/svg/close.svg' : '/svg/burger.svg'}
                    alt="burger-menu"
                    className={styles.burgerIcon}
                  />
                </div>
              </div>
            </div>
            <nav className={styles.navLinks}>
              <Link
                href="/"
                className={cn(styles.navLink)}
                onClick={handleMenuToggle}
              >
                Home
              </Link>
              <Link
                href="/explore"
                className={cn(styles.navLink)}
                onClick={handleMenuToggle}
              >
                Explore
              </Link>
              <Link
                href="/create"
                className={cn(styles.navLink)}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleMenuToggle();
                  if (connectedAccount) {
                    router.push('/create');
                  } else {
                    connect();
                  }
                }}
              >
                Create NFT
              </Link>
              <Link
                href="/blog"
                className={cn(styles.navLink)}
                onClick={handleMenuToggle}
              >
                Blog
              </Link>
              <Link
                href="/about"
                className={cn(styles.navLink)}
                onClick={handleMenuToggle}
              >
                About
              </Link>
              {/* <Link
              href="/careers"
              className={cn(styles.navLink)}
              onClick={handleMenuToggle}
            >
              Careers
            </Link> */}
              <Link
                href="/profile"
                className={cn(styles.navLink)}
                onClick={handleMenuToggle}
              >
                Profile
              </Link>
              {connectedAccount && (
                <div className={cn(styles.navLink)} onClick={handleDisconnect}>
                  Disconnect
                </div>
              )}
            </nav>
          </div>
          <div className={styles.actions}>
            <Divider />
            <div className={styles.socialMediaSection}>
              <span className={styles.headerMobileTitle}>
                Join the community
              </span>
              <div className={styles.socialMediaButtons}>
                <SocialMediaButton type="telegram" url={TELEGRAM_URL} />
                <SocialMediaButton type="xcom" url={XCOM_URL} />
                <SocialMediaButton type="medium" url={MEDIUM_URL} />
              </div>
            </div>

            <div className={styles.helpSection}>
              <span className={styles.headerMobileTitle}>Need Help?</span>
              <Button
                onClick={() => {
                  handleMenuToggle();
                  setContactUsModalOpen(true);
                }}
                variant="filled"
                color="gray"
                size="small"
              >
                Contact Support
              </Button>
            </div>
          </div>
        </div>
      )}
      {contactUsModalOpen && (
        <ContactUsModal onCloseModal={() => setContactUsModalOpen(false)} />
      )}
      {swapModalOpen && <SwapModal onClose={() => setSwapModalOpen(false)} />}
    </>
  );
};
