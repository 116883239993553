import cn from 'classnames';
import styles from './Select.module.scss';
import { useEffect, useRef, useState } from 'react';
import { Option } from './Option/Option';

function ArrowSvg({ className }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6 9L12 15L18 9"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Select({
  options,
  onChange,
  className,
  disabled,
  selected,
  title,
  ...args
}) {
  const [isOpen, setIsOpen] = useState(false);
  const SelectRef = useRef(null);

  const arrowClassName = cn(styles.arrow, {
    [styles.reverse]: isOpen,
  });

  useEffect(() => {
    const handleClick = (e) => {
      const { target } = e;
      if (target instanceof Node && !SelectRef.current?.contains(target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [isOpen]);

  const handleOptionClick = (option) => {
    setIsOpen(false);
    onChange(option);
  };

  const selectClassName = cn(styles.select, {
    [styles.selectActive]: isOpen,
    [styles.disabled]: disabled,
  });

  const wrapperClassName = cn(styles.wrapper, className);

  const toggleSelect = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={wrapperClassName} ref={SelectRef} {...args}>
      {title && <div className={styles.heading}>{title}</div>}
      <div className={selectClassName} onClick={toggleSelect}>
        <div className={styles.option}>
          {selected.icon && (
            <img
              loading="lazy"
              src={selected.icon}
              className={styles.icon}
              alt="currency"
            />
          )}
          {selected.label}
        </div>
        <ArrowSvg className={arrowClassName} />
      </div>
      {isOpen && (
        <div className={styles.menu}>
          {options
            .filter((opt) => opt.value !== selected.value)
            .map((option) => (
              <Option
                option={option}
                key={option.value}
                onClick={handleOptionClick}
              />
            ))}
        </div>
      )}
    </div>
  );
}
