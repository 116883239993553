import React from 'react';
import PropTypes from 'prop-types';

export const Flex = ({
  children,
  justify = 'flex-start',
  align = 'stretch',
  wrap = 'nowrap',
  gap = 0,
  style = {},
  vertical = false,
  className = '',
  flex,
  width = '100%',
  grow = 0,
  onClick,
  maxWidth,
}) => {
  return (
    <div
      className={className}
      style={{
        width: maxWidth || width || undefined,
        display: 'flex',
        flexDirection: vertical ? 'column' : 'row',
        justifyContent: justify,
        alignItems: align,
        flexWrap: wrap,
        gap: `${gap}px`,
        flex,
        flexGrow: grow,
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

Flex.propTypes = {
  children: PropTypes.node,
  justify: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  align: PropTypes.oneOf([
    'stretch',
    'flex-start',
    'flex-end',
    'center',
    'baseline',
  ]),
  wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  gap: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  vertical: PropTypes.bool,
  flex: PropTypes.string,
  grow: PropTypes.number,
};
