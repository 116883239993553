import {
  BAHAMUT_MAINNET_CHAIN_EXPLORER,
  BAHAMUT_MAINNET_RPC_URL,
} from '@/constants';
import { useWeb3Modal } from '@web3modal/ethers5/react';

const addBahamut = async () => {
  if (window.ethereum) {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x142d',
          chainName: process.env.NETWORK_NAME,
          nativeCurrency: {
            name: 'FTN',
            symbol: 'FTN',
            decimals: 18,
          },
          rpcUrls: [BAHAMUT_MAINNET_RPC_URL],
          blockExplorerUrls: [BAHAMUT_MAINNET_CHAIN_EXPLORER],
        },
      ],
    });
  }
};

export const useConnectBahamut = (userChainId) => {
  const { open } = useWeb3Modal();

  const connect = () => {
    addBahamut();

    if (!userChainId) {
      open();
      return;
    }

    if (userChainId !== Number(process.env.CHAIN_ID)) {
      open({
        view: 'Networks',
      });
      return;
    }
  };

  return { connect };
};
