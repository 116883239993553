export const ShareIcon = ({ color = 'white', className, onClick, size }) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <path
        d="M9.99828 0.464844C8.75553 0.464844 7.74828 1.47209 7.74828 2.71484C7.74828 2.90759 7.77903 3.1476 7.82478 3.3261L3.81003 6.12809C3.45078 5.88509 2.96404 5.71484 2.49829 5.71484C1.25554 5.71484 0.248291 6.72209 0.248291 7.96484C0.248291 9.20759 1.25554 10.2148 2.49829 10.2148C2.97229 10.2148 3.44029 10.0386 3.80329 9.78885L7.82628 12.6133C7.77678 12.7986 7.74828 13.0138 7.74828 13.2148C7.74828 14.4576 8.75553 15.4648 9.99828 15.4648C11.241 15.4648 12.2483 14.4576 12.2483 13.2148C12.2483 11.9721 11.241 10.9648 9.99828 10.9648C9.52428 10.9648 9.06227 11.1359 8.70003 11.3864L4.66654 8.5641C4.71529 8.37885 4.74828 8.16584 4.74828 7.96484C4.74828 7.76384 4.71978 7.54711 4.67028 7.36186L8.69403 4.54784C9.05328 4.79009 9.53253 4.96484 9.99828 4.96484C11.241 4.96484 12.2483 3.95759 12.2483 2.71484C12.2483 1.47209 11.241 0.464844 9.99828 0.464844Z"
        fill={color}
        style={{ transition: 'all 0.3s' }}
      />
    </svg>
  );
};
