import { createAction } from '@reduxjs/toolkit';

import { ACTIONS } from './constants';

export const setMenuActive = createAction(ACTIONS.SET_MENU_ACTIVE);

export const setOwnedNFTs = createAction(ACTIONS.SET_OWNED_NFTS);

export const setOnSaleNFTs = createAction(ACTIONS.SET_ON_SALE_NFTS);

export const setCreatedNFTs = createAction(ACTIONS.SET_CREATED_NFTS);

export const setIsTransferMode = createAction(ACTIONS.SET_IS_TRANSFER_MODE);

export const setTranferList = createAction(ACTIONS.SET_TRANSFER_LIST);

export const setTransferApproval = createAction(ACTIONS.SET_TRANSFER_APPROVAL);
