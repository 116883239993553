import React from 'react';
import styles from './Loader.module.scss';
import cn from 'classnames';

export const Loader = ({ fullScreen, big, className, size = 50 }) => {
  if (fullScreen) {
    return (
      <div className={styles.fullScreenLoader}>
        <img
          alt="loading"
          src="/svg/loading.svg"
          className={styles.bigLoader}
        />
      </div>
    );
  }

  if (big) {
    return (
      <div className={cn(styles.bigLoaderContainer, className)}>
        <img
          alt="loading"
          src="/svg/loading.svg"
          className={styles.bigLoader}
        />
      </div>
    );
  }

  return (
    <img
      src="/svg/loading.svg"
      className={cn(styles.loader, className)}
      alt="loading"
      height={size}
    />
  );
};
