import cn from 'classnames';
import styles from './Title.module.scss';
import { ArrowIcon, Button } from '..';
import { useRouter } from 'next/router';
import { BLACK_COLOR, WHITE_COLOR } from '@/constants';
import { useState } from 'react';
import { useAdaptiveWidth } from '@/hooks/useAdaptiveWidth';

export function Title({ className, children, link }) {
  const wrapperClassName = cn(styles.wrapper, className);
  const { push } = useRouter();
  const { isTablet } = useAdaptiveWidth();
  const [hovered, setHovered] = useState(false);

  const handleLinkClick = () => {
    if (!link) return;
    push(link.url);
  };

  return (
    <div className={wrapperClassName}>
      <h1 className={styles.title}>{children}</h1>
      {link && !isTablet && (
        <Button
          onClick={handleLinkClick}
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <span>{link.text}</span>
          <ArrowIcon
            color={hovered ? WHITE_COLOR : BLACK_COLOR}
            className={styles.icon}
          />
        </Button>
      )}
    </div>
  );
}
