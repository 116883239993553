export const WebsiteIcon = ({ color = 'black', className, onClick, size }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      style={{ width: size, height: size }}
    >
      <path
        d="M9.99934 1.67847C5.39684 1.67847 1.66602 5.4093 1.66602 10.0118C1.66602 14.6143 5.39684 18.3451 9.99934 18.3451C14.6018 18.3451 18.3327 14.6143 18.3327 10.0118C18.3327 5.4093 14.6018 1.67847 9.99934 1.67847ZM9.99934 3.34514C11.0993 3.34514 12.3077 5.87181 12.4918 9.17597L7.51684 9.18597C7.70017 5.88181 8.89934 3.34514 9.99934 3.34514ZM7.05684 4.01431C6.34017 5.40848 5.95684 7.25681 5.86434 9.18014L3.38103 9.17264C3.62936 6.90181 5.15851 4.99681 7.05684 4.01431ZM12.946 4.01848C14.8443 5.00098 16.3343 6.85264 16.6218 9.18597L14.1393 9.17847C14.061 7.17847 13.6185 5.42015 12.946 4.01848ZM3.38601 10.8485L5.86851 10.8276C5.96101 12.751 6.34684 14.631 7.06017 16.0101C5.06684 15.011 3.67268 13.0551 3.38601 10.8485ZM7.50351 10.8551L12.4785 10.8443C12.2952 14.1476 11.0993 16.6785 9.99934 16.6785C8.89934 16.6785 7.68685 14.1593 7.50351 10.8551ZM14.1435 10.8468L16.6152 10.8393C16.3285 13.0468 14.9352 15.0185 12.9427 16.001C13.6827 14.471 14.051 12.7701 14.1435 10.8468Z"
        fill={color}
        style={{ transition: 'all 0.3s' }}
      />
    </svg>
  );
};
