import { Contract, ethers } from 'ethers';
import abi from '../../abis/src/Swap/Swap.json';
import withdrawAbi from '../../abis/src/Swap/Withdraw.json';
import { BAHAMUT_MAINNET_RPC_URL } from '@/constants';

const ZeroAddress = '0x0000000000000000000000000000000000000000';
const WITHDRAW_CONTRACT_ADDRESS = process.env.WITHDRAW_CONTRACT_ADDRESS;

export const getContract = async ({ contractAddress, walletProvider }) => {
  try {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const contract = new Contract(contractAddress, abi.abi, signer);

    return contract;
  } catch (error) {
    return error;
  }
};

export const initiateSwap = async ({
  amount,
  tokenAddress,
  walletProvider,
  contractAddress,
}) => {
  try {
    const contract = await getContract({
      contractAddress,
      walletProvider,
    });

    return contract.initiateSwap(tokenAddress, amount, ZeroAddress);
  } catch (error) {
    return error;
  }
};

export const initiateSwapUSDT = async ({
  walletProvider,
  contractAddress,
  usdtContactAddress,
  valueSend,
  decimals,
}) => {
  try {
    const contract = await getContract({
      contractAddress,
      walletProvider,
    });

    return contract.initiateSwap(
      usdtContactAddress,
      Number(valueSend) * 10 ** decimals,
      ZeroAddress,
    );
  } catch (error) {
    return error;
  }
};

export const initiateSwapNative = async ({
  walletProvider,
  contractAddress,
  valueSend,
}) => {
  try {
    const contract = await getContract({
      contractAddress,
      walletProvider,
    });

    return contract.initiateSwap(ZeroAddress, 0, ZeroAddress, {
      value: ethers.utils.parseEther(valueSend),
    });
  } catch (error) {
    return error;
  }
};

export const checkLimits = async ({ userAddress }) => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(
      BAHAMUT_MAINNET_RPC_URL,
    );

    const contract = new Contract(
      WITHDRAW_CONTRACT_ADDRESS,
      withdrawAbi.abi,
      provider,
    );

    return contract.etherLimits(userAddress);
  } catch (error) {
    return error;
  }
};

export const withdraw = async ({ walletProvider }) => {
  try {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(
      WITHDRAW_CONTRACT_ADDRESS,
      withdrawAbi.abi,
      signer,
    );

    return await contract.withdraw();
  } catch (error) {
    return error;
  }
};
