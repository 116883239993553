import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import styles from './FileInput.module.scss';
import { Button, Flex } from '..';

const fileTypeToText = {
  'image/jpeg': 'JPG',
  'image/png': 'PNG',
  'application/pdf': 'PDF',
  'application/msword': 'DOC',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'DOCX',
};

export const FileInput = ({ name, accept = '', multiple = false }) => {
  const [files, setFiles] = useState([]);
  const { field } = useController({ name });

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files || []);
    setFiles([...files, ...newFiles]);
    field.onChange([...files, ...newFiles]);
  };

  const removeFile = (fileName) => {
    const updatedFiles = files.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
    field.onChange(updatedFiles);
  };

  return (
    <Flex
      vertical
      align="center"
      justify="center"
      className="file-input-container"
      gap={32}
    >
      <label htmlFor={name} className={styles.uploadLabel}>
        <input
          id={name}
          type="file"
          accept={accept}
          multiple={multiple}
          onChange={handleFileChange}
          className={styles.hiddenInput}
        />
        <Flex vertical align="center" justify="center" gap={8}>
          <img
            loading="lazy"
            src="/svg/fileUploadClous.svg"
            alt="upload icon"
          />
          <p>Click to upload or drag and drop</p>
          <p className={styles.dimmedText}>
            Have supporting documents or files to share? Please, upload them
            here.
            <br />
            Accepted file formats: PDF, DOC, DOCX, JPG, PNG. Max file size:
            10MB.
          </p>
        </Flex>
      </label>

      {/* File list */}
      <Flex vertical gap={16}>
        {files.map((file) => (
          <Flex
            justify="space-between"
            align="center"
            key={file.name}
            className={styles.fileInputItem}
          >
            <Flex gap={4}>
              <img loading="lazy" src="/svg/fileIcon.svg" alt="file-icon" />

              <Flex vertical>
                <Flex justify="space-between">
                  <p className={styles.fileName}>{file.name}</p>
                  <Button
                    variant="ghost"
                    onClick={() => removeFile(file.name)}
                    icon="/svg/close.svg"
                    className={styles.icon}
                  />
                </Flex>
                <Flex gap={8} className={styles.dimmedText}>
                  <p>{(file.size / 1000000).toFixed(2)} MB</p>
                  <p>{fileTypeToText[file.type]}</p>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
